<template>
  <b-container fluid>
    <b-form-group v-if="options.length > 1">
      <b-form-select
        v-model="email"
        :options="options"
      />
    </b-form-group>
    <Dash
      v-if="email"
      :params="{
        email: email
      }"
      url="representative"
    />
  </b-container>
</template>

<script>
import { BFormGroup, BFormSelect, BContainer } from 'bootstrap-vue';
import Dash from '../components/Dash.vue';

import ComercialAgentThoth from '@/service/comercial-agents/thoth';

export default {
    components: {
        Dash,
        BContainer,
        BFormGroup,
        BFormSelect,
    },

    data() {
        return {
            partner: this.$store.state.user.data.email,
            email: null,
            comercialAgents: [],
        };
    },

    computed: {
        options() {
            const options = this.comercialAgents.map(item => ({
                value: item.email,
                text: item.name,
            })).sort((a, b) => a.text.localeCompare(b.text));

            options.unshift({
                value: null,
                text: 'Selecione um representante',
            });

            return options;
        },
    },

    async mounted() {
        this.callLoading(true);
        const { data } = await ComercialAgentThoth.getAllAgents();
        const representative = data.find(item => this.partner === item.email);
        if (representative) {
            this.email = representative.email;
            this.callLoading(false);
            return;
        }
        this.comercialAgents = data;
        this.callLoading(false);
    },
};
</script>
