import Thoth from '../base/thoth';

class ComercialAgentThoth extends Thoth {
    getAllAgents() {
        return this.fetch({
            url: 'get-email-comercial-agent',
        });
    }
}

export default new ComercialAgentThoth();
